<template>
    <div id="status-pedido">
        <div class="top">
            <NavBarCardapio :pedidos="[]"/>
            <div class="container container-menu-cardapio" v-if="$store.getters.screen_size.width <= 767">                
                <div class="row">
                    <span class="titulo-cardapio">
                        {{titulo_step}}
                    </span>
                </div>
            </div>
        </div>
        <div class="btn_voltar">
            <div class="container container-cardapio">
                <div class="row">
                    <div class="col-2" style="display: flex;">
                        <div class="posicao-btn-voltar">
                            <a class="btn-voltar" href="javascript:history.back()">
                                <v-icon>fas fa-arrow-left</v-icon>
                                <span>Voltar</span>
                            </a>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="estado-pedido">
                            <figure v-if="pedido[0].status == 1 || pedido[0].status == 2 || pedido[0].status == 4">
                                <img src="@/assets/images/confirmando-pedido.png" alt="Confirmando pedido">
                            </figure>
                            <figure v-if="pedido[0].status == 3">
                                <img src="@/assets/images/pedido-em-preparo.png" alt="Pedido em preparo">
                            </figure>
                            <figure v-if="pedido[0].status == 5 || pedido[0].status == 10">
                                <img src="@/assets/images/pedido-a-caminho.png" alt="Pedido a caminho">
                            </figure>
                            <figure v-if="pedido[0].status == 6">
                                <img src="@/assets/images/pedido-finalizado.png" alt="Pedido finalizado">
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="texto">
            <h2 v-if="pedido[0].status == 1">
                AGUARDANDO PAGAMENTO
            </h2>
            <h2 v-if="pedido[0].status == 2">
                AGUARDANDO APROVAÇÃO DO PEDIDO
            </h2>
            <h2 v-if="pedido[0].status == 3">
                SEU PEDIDO ESTÁ SENDO PREPARADO PELO RESTAURANTE
            </h2>
            <h2 v-if="pedido[0].status == 4">
                PEDIDO REPROVADO
            </h2>
            <h2 v-if="pedido[0].status == 5">
                SEU PEDIDO SAIU PARA ENTREGA!
            </h2>
            <h2 v-if="pedido[0].status == 6">
                PEDIDO FINALIZADO
            </h2>
            <h2 v-if="pedido[0].status == 10">
                SEU PEDIDO PODE SER RETIRADO!
            </h2>
        </div>
        <div class="estado-animacao">
            <lottie-animation
                ref="anim_aguardando_pagamento"
                :animationData="require('@/assets/gifs/aguardando-pagamento.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                v-show="pedido[0].status == 1"
            />
            <lottie-animation
                ref="anim_aguardando_aprovacao"
                :animationData="require('@/assets/gifs/aguardando-aprovacao.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                v-show="pedido[0].status == 2"
            />
            <lottie-animation
                ref="anim_pedido_em_preparo"
                :animationData="require('@/assets/gifs/pedido-em-preparo.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                v-show="pedido[0].status == 3"
            />
            <lottie-animation
                ref="anim_pedido_reprovado"
                :animationData="require('@/assets/gifs/pedido-reprovado.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                v-show="pedido[0].status == 4"
            />
            <lottie-animation
                ref="anim_pedido_a_caminho"
                :animationData="require('@/assets/gifs/pedido-a-caminho.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                v-show="pedido[0].status == 5"
            />
            <lottie-animation
                ref="anim_pedido_finalizado"
                :animationData="require('@/assets/gifs/pedido-finalizado.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                v-show="pedido[0].status == 6"
            />
            <lottie-animation
                ref="anim_pedido_pronto_para_retirada"
                :animationData="require('@/assets/gifs/pedido-pronto-para-retirada.json')"
                :loop="true"
                :autoPlay="true"
                :speed="1"
                v-show="pedido[0].status == 10"
            />
        </div>
        <div class="info-pedido">
            <h2>
                Nº DO PEDIDO
            </h2>
            <span>
                {{pedido[0].identificador}}
            </span>
        </div>
        <div class="wrapper-logoFinerFoods">
            <LogoFinerFoods/>
        </div>
    </div>
</template>

<script>
    import store from '@/store'
    import LogoFinerFoods from '@/components/LogoFinerFoods.vue'
    import LottieAnimation from 'lottie-web-vue' 
	import io from 'socket.io-client';
    import NavBarCardapio from '@/components/NavBarCardapio.vue'

	export default {
		name: 'StatusPedido',
        components: {
            NavBarCardapio,
            LogoFinerFoods,
            LottieAnimation
        },
		data: () => ({
            pedido: [{
                status: null,
            }],
			socket: io(process.env.VUE_APP_SOCKET_URL, { query: { token_user: store.getters.token }, transports:['websocket', 'polling'] }),
            titulo_step: 'Status do Pedido'
		}),
        methods: {
            async init(){
                let pedido_temp = await store.dispatch('getStatusPedido', this.$route.params.identificador_pedido);

                if(pedido_temp.dados_pedido.length > 0){
                    this.pedido = pedido_temp.dados_pedido
                }else{
                    this.pedido = [{
                        status: null,
                    }]
                }
            },
        },
        async mounted(){
            this.init()

            this.socket.on('pedidoStatus', async () => {
                this.init()
            })
		},
	}
</script>

<style lang="scss">
    #status-pedido{
        border-top: 1px solid #C8C8C8;
        @media (max-width: 767px){
            border-top: 0;
            .top{
                width: 100%;
                background-color: #fff;
                z-index: 2;
                #navBarCardapio{
                    .container-cardapio{
                        background-color: #262626;
                    }
                }
                .container-menu-cardapio{
                    background-color: #262626;                                  
                    .titulo-cardapio{
                        font-family: 'Poppins';
                        background-color: #262626;
                        width: 100%;
                        font-size: 22px;
                        color: #fff;
                        font-weight: 600;
                        padding-left: 15px;
                        @media (max-width: 375px){
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        font-family: 'Poppins';
        background-color: #fff;
        .btn_voltar{
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            .posicao-btn-voltar{
                display: flex;
                align-items: center;
                .btn-voltar{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    svg{
                        transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                        path{
                            fill: $primaryfontcolor;
                        }
                    }
                    span{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        margin-left: 5px;
                        color: $primaryfontcolor;
                        font-weight: 500;
                        @media (max-width: 767px){
                            display: none;
                        }
                    }
                    &:hover{
                        svg{
                            transform: translateX(-10px);
                        }
                    }
                    @media (max-width: 750px){
                        left: 10%;
                    }
                    @media (max-width: 470px){
                        left: 5%;
                    }
                    @media (max-width: 320px){
                        left: 3%;
                        span{
                            display: none;
                        }
                        &:hover{
                            svg{
                                transform: translateX(-5px);
                            }
                        }
                    }
                }
            }
            .container-cardapio{
                padding-top: 0;
                padding-bottom: 0;
            }
        }
        .estado-pedido{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media (max-width: 400px){
                img{
                    width: 250px;
                }
            }
        }
        .texto{
            color: #FE8204;
            h2{
                text-align: center;
            }
        }
        .estado-animacao{
            display: flex;
            flex-wrap: wrap;
            text-align: center;
            justify-content: center;
            align-items: center;
            svg{
                width: 250px !important;
                @media (max-width: 400px){
                    width: 150px !important;
                }
            }
        }
        .info-pedido{
            h2{
                text-align: center;
                color: #FE8204;
                font-size: 20px;
            }
            span{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                font-weight: 800;
            }
        }
    }
	.wrapper-logoFinerFoods{
		position: fixed;
		bottom: 5%;
		right: 5%;
		@media (max-width: 767px){
			display: none;
		}
	}
</style>